import { useEffect } from 'react';

import './App.css';

function App() {
  useEffect(() => {
    if (!window?.location?.href) return;
    if (window?.gtag) {
      window.gtag('event', 'page_view', {});
    }
  }, [window.location?.href]);

  return (
    <div className='app'>
      <video
        className='hero-video'
        src='scribes_loop.mp4'
        autoPlay
        muted
        loop
      />
      <div className='content'>
        <h1>Welcome to Crynode</h1>

        <h2>ICN 1 - Video & Image Generator (Beta)</h2>
        <p>
          Try our test integration: Solana payments + AI models for video and
          image generation:{' '}
          <a
            href='https://icn1.crynode.com/'
            target='_blank'
          >
            https://icn1.crynode.com
          </a>
          . Learn more:{' '}
          <a
            href='https://docs.crynode.com/the-network/poc-icn-1'
            target='_blank'
          >
            https://docs.crynode.com/the-network/poc-icn-1
          </a>
        </p>

        <h2>AICRYNODE Token</h2>
        <p>
          Our utility token, AICRYNODE, powers the Crynode network on the Solana
          blockchain, enabling access to services offered by AI Agents at
          discounted rates.
        </p>

        <h2>DEXes & Charts</h2>
        <p className='links'>
          <a
            href='https://www.geckoterminal.com/solana/pools/6Y6ABmtWu1uxacwyNwWf7fHA7nsHa6krXhseJqU7VT88'
            target='_blank'
            rel='noopener noreferrer'
          >
            Geckoterminal
          </a>
          <a
            href='https://www.dextools.io/app/en/solana/pair-explorer/6Y6ABmtWu1uxacwyNwWf7fHA7nsHa6krXhseJqU7VT88?t=1730200142984'
            target='_blank'
            rel='noopener noreferrer'
          >
            Dextools
          </a>
          <a
            href='https://birdeye.so/token/PD11M8MB8qQUAiWzyEK4JwfS8rt7Set6av6a5JYpump?chain=solana'
            target='_blank'
            rel='noopener noreferrer'
          >
            Birdeye
          </a>
        </p>

        <h2>Typen sees all</h2>
        <video
          className='hero-video'
          src='typen.mp4'
          autoPlay
          muted
          loop
        />
        <p>
          Don’t day-trade our token. This is a long-term project that will take
          months of development to reach its true potential.
        </p>

        <h2>Sacred Documentation</h2>
        <p>
          Read more about our project in the sacred documentation:{' '}
          <a
            href='https://docs.crynode.com/'
            target='_blank'
          >
            https://docs.crynode.com
          </a>
        </p>

        <h2>Join the network</h2>
        <p>
          Want to get involved? Send a simple CV to{' '}
          <a href='mailto:hi@crynode.com'>hi@crynode.com</a>. We're especially
          seeking developers skilled in JavaScript / Fullstack.
        </p>
      </div>
    </div>
  );
}

export default App;
