import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { Buffer } from 'buffer';

import { IS_ENV_DEVELOPMENT, IS_ENV_PRODUCTION, config } from './config';

// Add this interface declaration at the top of the file, after the imports
declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

window.dataLayer = window.dataLayer || [];

function gtag(...args: any[]) {
  window.dataLayer.push(args);
}

try {
  gtag('consent', 'default', {
    ad_storage: 'granted',
    ad_user_data: 'granted',
    ad_personalization: 'granted',
    analytics_storage: 'granted',
  });
  gtag('js', new Date());
} catch (e) {
  console.error('gtag error', e);
}
window.gtag = gtag;

const params = new URL(document?.location as unknown as string)?.searchParams;
const isAdmin = localStorage?.getItem('isAdmin');

function createGaHtmlTag(id) {
  var js = document.createElement('script');
  js.setAttribute('async', '');
  js.onerror = (e) => {
    console.error('Failed to load Google Tag Manager script', e);
    // Sentry.captureException(e);
  };
  js.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
  document.head.appendChild(js);
}

const mockTrackingFunctions = () => {
  window.gtag = () => {};
};

const initAnalytics = () => {
  if (IS_ENV_DEVELOPMENT || isAdmin) {
    mockTrackingFunctions();
    return;
  }
  if (IS_ENV_PRODUCTION && window?.location?.hostname !== 'localhost') {
    // google analytics
    if (config.googleAnalyticsId) {
      createGaHtmlTag(config.googleAnalyticsId);

      gtag('config', config.googleAnalyticsId, {
        campaign: {
          medium:
            params.get('utm_medium') || localStorage?.getItem('utm_source'),
          source:
            params.get('utm_source') || localStorage?.getItem('utm_medium'),
          name:
            params.get('utm_campaign') || localStorage?.getItem('utm_campaign'),
          content:
            params.get('utm_content') || localStorage?.getItem('utm_content'),
        },
        send_page_view: false,
      });

      // already got set default values, but later change it to let user update it:
      // if (localStorage?.getItem?.('gtagc')) {
      //   gtag('consent', 'update', {
      //     ad_user_data: 'granted',
      //     ad_personalization: 'granted',
      //     ad_storage: 'granted',
      //     analytics_storage: 'granted',
      //   });
      // }

      // gtag('config', config.googleAdsId, {
      //   campaign: {
      //     medium: params.get('utm_medium'),
      //     source: params.get('utm_source'),
      //     name: params.get('utm_campaign'),
      //     content: params.get('utm_content'),
      //   },
      // });
    }
  }
};

try {
  initAnalytics();
} catch (e) {
  console.error('initAnalytics error', e);
}

window.Buffer = Buffer;

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
